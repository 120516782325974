import React, { useCallback, useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import Selector from '../Selector/Selector';
import './PromoCalculator.css';
import { IMainScreenState } from "../../types/IMainScreenState";
import PhoneInput from 'react-phone-input-2';
import CustomInput from '../CustomInput/CustomInput';
import ActivateCodePromocodeController from '../../core/activateCodePromocode/controller';
import config from '../../core/config/config';

export interface ICalculatorProps {
  data: IMainScreenState;
  onClick?: () => void;
  onDataChange?: (state: IMainScreenState) => void;
  promoCalculator: boolean;
}

const PromoCalculator: React.FC<ICalculatorProps> = ({ data, onClick, onDataChange = () => { }, promoCalculator }) => {
  const [price, setPrice] = useState(
    {
      newPrice: 379,
      discount: 34,
      benefit: 0,
      month: 12,
    }
  );
  const controller = new ActivateCodePromocodeController();
  const [promocodeDiscount, setPromocodeDiscount] = useState(1);
  const [phone, setPhone] = useState("");
  const [phoneFlag, setPhoneFlag] = useState(false);
  const [code, setCode] = useState(false);
  const [codeData, setCodeData] = useState("");
  const [error, setError] = useState(false);
  const [codeReq, setCodeReq] = useState(true);
  const dayDiscount = (price.newPrice / price.month) - ((price.newPrice / price.month) * (price.discount / 100));
  const dayDiscountWithPromocode = (code && !error) ? (dayDiscount * (promocodeDiscount / 100)) : 0;


  const getPrice = useCallback((priceInfo) => {
    setPrice(priceInfo);
    onDataChange({
      ...data,
      months: priceInfo.month,
      price: Math.floor((priceInfo.newPrice - priceInfo.benefit - ((code && !error) ? (((priceInfo.newPrice - priceInfo.benefit) * promocodeDiscount) / 100) : 0))),
      discount: priceInfo.discount,
    });
  }, [data, onDataChange, setPrice]);

  const handleCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value.replace(/\W/g, '').toUpperCase();
      setCodeData(text);
      if(text.length > 3) {
        setCodeReq(false);
      } else {
        setCodeReq(true);
      }
  }, [setCodeData, codeData])

  const handlePhone = useCallback(
    (phone: string) => {
      setPhone(phone);
      onDataChange({
        ...data,
        phone,
      })
      if (phone.length >= 4 && phone.length <= 20) {
        setPhoneFlag(true);
      } else {
        setPhoneFlag(false);
      }
  }, [setPhone, setPhoneFlag, data, onDataChange])

  const onClickCode = useCallback(async () => {
    const activateResult = await controller.activateCodePromocode(codeData);
    if (activateResult.success) {
      setCode(true);
      setError(false);
      setPromocodeDiscount(activateResult.data.discount);
      const sumBenefit = price.newPrice - price.benefit;
      onDataChange({
        ...data,
        price: Math.floor((sumBenefit - ((sumBenefit * activateResult.data.discount) / 100) )),
      });
      return;
    } else {
      const sumBenefit = price.newPrice - price.benefit;
      setError(true);
      setCode(false);
      setPromocodeDiscount(1);
      onDataChange({
        ...data,
        price: Math.floor(sumBenefit),
      });
    }
  }, [setCode, codeData, onDataChange, setPromocodeDiscount, promocodeDiscount])

  const onClickResetCode = useCallback(() => {
    setCodeData('');
    const sumBenefit = price.newPrice - price.benefit;
    setPromocodeDiscount(1);
      onDataChange({
        ...data,
        price: Math.floor(sumBenefit),
      });
    setCodeReq(true);
    setCode(false);
  }, [setCode])

  return (
    <div className="CalculatorBlock">
      <div>
        <p className="CalculatorTitle">Оформить подписку</p>
        <div className="CalculatorPhoneInput">
          <PhoneInput country={'ru'} value={phone} inputProps={{required: true, autoFocus: true}} onChange={handlePhone} specialLabel="" />
        </div>
        <p className="CalculatorAdditionalText">На этот номер телефона будет оформлена подписка</p>
        <div className="CalculatorBottomBlock">
          <div className="CalculatorBottomLeftBlock">
            <p className="CalculatorTextFirst">Месяцев</p>
            <p className="CalculatorText">Цена</p>
            {price.month !== 1 && <p className="CalculatorText">Скидка за период</p>}
            {code && <p className="CalculatorText">Скидка по коду</p>}
            {<p className="CalculatorTextSum">Итого к оплате</p>}
            {price.month !== 1 && <p className="CalculatorTextGray">Цена за 1 месяц</p>}
          </div>
          <div className="CalculatorBottomRightBlock">
            <Selector getPrice={getPrice} promoCalculator={promoCalculator} />
            <div className="CalculatorBottomRightBlockText">
              <p className="CalculatorText">{(price.newPrice).toLocaleString('ru-RU') || 0} <span className="RubleIcon">₽</span></p>
              {price.month !== 1 && <p className="CalculatorText">-{price.discount}%</p>}
              {code && <p className="CalculatorText">-{promocodeDiscount}%</p>}
              {<p className="CalculatorTextSum">{(price.newPrice - price.benefit - ((code && !error) ? (((price.newPrice - price.benefit) * promocodeDiscount) / 100) : 0)).toLocaleString('ru-RU') || 0} <span className="RubleIcon">₽</span></p>}
              {price.month !== 1 && <p className="CalculatorTextGray">{((price.newPrice / price.month) - ((price.newPrice / price.month) * (price.discount / 100)) - dayDiscountWithPromocode).toLocaleString('ru-RU') || 0} <span className="RubleIconGray">₽</span></p>}
            </div>
          </div>
        </div>
       
      </div>
      <div className="CalculatorFooter">
        <div className="CalculatorDiscountContainer">
          <div className="CalculatorDiscountBlock">
            {!code && <CustomInput placeholder="У меня есть код на скидку" onChange={(value) => handleCodeChange(value)} inputClassName="CalculatorDiscountInput" minLength={4} maxLength={20} />}
            {code && !error && <p className="CalculatorCodeText">Ваш код успешно применен</p>}
            {!code && <CustomButton title="Применить" onClick={onClickCode} className="CalculatorDiscountButton" disabled={codeReq} />}
          </div>
          {code && <div className="CalculatorErrorBlock">
             <CustomButton title="Отменить" onClick={onClickResetCode} className="CalculatorDiscountButton" />
          </div>}
        </div>
        {error && <div className="CalculatorErrorBlock">
           <p className="CalculatorCodeTextError">Указанный код на скидку не найден или не действителен</p>
        </div>}
        
        <CustomButton title="Перейти к оплате" onClick={onClick} disabled={!phoneFlag} />
        <p className="CalculatorOffer">
          {"Оформляя покупку, вы соглашаетесь с "}
          <a className="CalculatorLink" href={config.privacyUrl} target='_blanc'>Договором оферты</a>
        </p>
      </div>
    </div>
  );
}

export default PromoCalculator;
function setError(arg0: any) {
  throw new Error('Function not implemented.');
}

