import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BuySubscription from '../screens/BuySubscription/BuySubscription';
import CodeActivated from '../screens/CodeActivated/CodeActivated';

const PromoRoutes: React.FC = () => {

  return (
    <div>
      <Switch>
        <Route
          exact path='/'
          component={BuySubscription}
        />
        <Route
          path='/activated'
          component={CodeActivated}
        />
      </Switch>
    </div>
  );
}

export default PromoRoutes;
