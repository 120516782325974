import config from "../config/config";
import httpService, {IResponse} from "../http/HttpService";

const PROMO_CODES_CODE_URL = `${config.apiUrl}/promocode-certificates`;

interface IActivatePromocode {
  promocode: string;
}

class ActivatePromoCodeCodeApi {
  activate(data: IActivatePromocode): Promise<IResponse> {
    return httpService.get(`${PROMO_CODES_CODE_URL}/verification?promocode=${data.promocode}`, undefined, {headers: {"Content-type": "application/pdf"}, encoding: "binary",});
  }
}

export default ActivatePromoCodeCodeApi;

