import React from 'react';
import './CustomInput.css';

export interface ICustomInputProps {
  placeholder: string;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  inputClassName?: string;
  minLength?: number;
  maxLength?: number;
  type?: string;
}

const CustomInput: React.FC<ICustomInputProps> = ({
  placeholder,
  defaultValue = '',
  onChange,
  className, inputClassName= "Email",
  minLength, maxLength,
  type
}) => {
  return (
    <div className={className}>
      <input
        className={inputClassName}
        placeholder={placeholder}
        onChange={onChange}
        minLength={minLength}
        maxLength={maxLength}
        type={type}
        defaultValue={defaultValue}
      />
    </div>
  );
}

export default CustomInput;
