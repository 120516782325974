import React, { useCallback, useState } from 'react';
import BottomMainBlock from '../../components/BottomMainBlock/BottomMainBlock';
import Footer from '../../components/Footer/Footer';
import UpperMainBlock from '../../components/UpperMainBlock/UpperMainBlock';
import BuyGiftSubscriptionController from "../../core/buyGiftSubscription/controller";
import { IMainScreenState } from "../../types/IMainScreenState";
import config from '../../core/config/config';
import domainRouter from "../../navigation/domainRouter";
import debounce from "lodash.debounce";
import { useHolidaysTheme } from '../../themes/useTheme';
import ym from 'react-yandex-metrika';

const controller = new BuyGiftSubscriptionController();
const MainScreen: React.FC = () => {
  console.log('podarok')
  const { isBlackFriday} = useHolidaysTheme()

  const [screenState, setScreenState] = useState<IMainScreenState>({
    email: "",
    confirmEmail: false,
    promocodeId: null,
    text: "" || null,
    months: 0,
    price: 0,
    phone: "",
    discount: 0,
    canPay: false,
    paymentMethod: 'tinkoff',
  });

  const handleDataChange = useCallback((newState: IMainScreenState) => {
    setScreenState({
      ...newState,
      canPay: isValidEmail(newState.email) && newState.confirmEmail,
    });
  }, []);

  const handleBuyClick = useCallback(debounce(async () => {
    ym('reachGoal', 'oplatit');
    window.generalPixel.postClick('a2');
    const { email, months, price, discount, paymentMethod } = screenState
    const promocode = await controller.buyPromoCode(email, months, price, discount, paymentMethod);

    setScreenState({ ...screenState, loading: true })

    if (!promocode) {
      setScreenState({ ...screenState, loading: false })
      alert("Не получилось. Попробуйте ещё раз.");
      return;
    }

    if (promocode.paymentLink) {
      window.location.href = promocode.paymentLink
    } else {
      setScreenState({ ...screenState, loading: false })
      alert("Не получилось сгенерировать форму для оплаты! Попробуйте ещё раз.");
    }

  }, 2000, { leading: true, trailing: false }), [screenState])

  const handleActivateClick = useCallback(() => {
    domainRouter.go(config.codeRoute);
  }, []);

  return (
    <>
      <UpperMainBlock
        data={screenState}
        onDataChange={handleDataChange}
        onBuyClick={handleBuyClick}
      />
      <BottomMainBlock
        onActivateClick={handleActivateClick}
      />
      <Footer backgroundColor={isBlackFriday ? '#EFECEC' : '#fff'} />
    </>
  );
}

export function isValidEmail(value: string): boolean {
  const EMAIL_REG = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REG.test(value);
}

export default MainScreen;
