import React, { useCallback, useState } from 'react';
import { useHistory } from "react-router-dom";
import CustomButton from '../../components/CustomButton/CustomButton';
import Footer from '../../components/Footer/Footer';
import PhoneInput from 'react-phone-input-2'
import './CodeActivation.css';
import ActivatePromoCodeController from '../../core/activatePromoCode/controller';
import domainRouter from "../../navigation/domainRouter";
import config from '../../core/config/config';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import { isValidEmail } from '../MainScreen/MainScreen';
import CustomInput from '../../components/CustomInput/CustomInput';

const prostoAppIcon = "images/ProstoappIcon.png";
const CodeActivationPresent = "images/Present.png";

const controller = new ActivatePromoCodeController();

const CodeActivation: React.FC = () => {
  const [activationType, setActivationType] = useState<'email' | 'phone'>('email');
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [confirmCorrectInput, setConfirmCorrectInput] = useState(false);
  const [error, setError] = useState<string | null>(null)
  const history = useHistory();

  const validateForm = useCallback(() => {
    // validate promo code
    const reg = /[A-Za-z0-9]+/;
    const validation = reg.test(code);
    if (!validation || code.length < 4) {
      setError('Неверный промокод')
      return false
    }
    // validate email
    if (activationType === 'email') {
      if (!isValidEmail(email)) {
        setError('Неверный email')
        return false
      }
    } else {
      console.log('phone', phone)
      if (phone.length < 4 || phone.length > 20) {
        setError('Неверный номер телефона')
        return false
      }
    }
    setConfirmCorrectInput(true)
    return true
  }, [activationType, code, phone, email, setError]);

  const handleActivation = useCallback(async () => {
    if (validateForm()) {
      const activatePromoCodeData: any = { promocode: code }

      if (activationType === 'email') {
        activatePromoCodeData['email'] = email
      } else {
        activatePromoCodeData['phone'] = phone
      }

      const activateResult = await controller.activatePromoCode(activatePromoCodeData);

      if (activateResult.success) {
        history.push("/activated");
        return;
      }

      setError(activateResult.errorMessage ?? "Неизвестная ошибка");
    }
  }, [activationType, code, phone, email, setError]);

  const handleBuy = useCallback(() => {
    domainRouter.go(config.giftRoute);
  }, [])

  const handleCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value.replace(/\W/g, '').toUpperCase();
      setCode(text);
      setError(null)
    }, [setCode, setError])

  const handlePhone = useCallback(
    (phoneNumber: string) => {
      setPhone(phoneNumber)
      setError(null)
    }, [setPhone, setError])

  const handleEmail = useCallback(
    (email: string) => {
      setEmail(email)
      setError(null)
    }, [setEmail, setError])

  const changeActivationType = useCallback((type: 'email' | 'phone') => {
    setActivationType(type)
    setConfirmCorrectInput(false)
    setError(null)
  }, [])


  return (
    <div className="CodeActivationBlock">
      <img className="ProstoAppIconMain" src={prostoAppIcon} />
      <div className="CodeActivationUpper">
        <p className="CodeActivationTitle">Активация промокода</p>
        <div className="ActivationTypeSelector">
          <button
            className={
              activationType === 'email' ? "ActivationTypeSelectorButtonActive" : "ActivationTypeSelectorButton"
            }
            onClick={() => changeActivationType('email')}>
            Email
          </button>
          <button
            className={
              activationType === 'phone' ? "ActivationTypeSelectorButtonActive" : "ActivationTypeSelectorButton"
            }
            onClick={() => changeActivationType('phone')} >
            Телефон
          </button>
        </div>
        <div className="CodeActivationInfo">
          Используйте указанный email или номер телефона для входа в приложение
        </div>
        <div className="CodeActivationInputBlock">
          <div className="CodeActivationInput">
            {activationType === 'phone' ?
              <PhoneInput
                enableLongNumbers
                country={'ru'}
                value={phone}
                inputProps={{ required: true, autoFocus: false }}
                onChange={handlePhone}
                specialLabel=""
              /> :
              <CustomInput
                className="CodeActivationEmailInput"
                placeholder="Электронная почта"
                defaultValue={email}
                onChange={(e) => handleEmail(e.target.value)}
              />
            }
          </div>
          <div className="CodeActivationInput">
            <input placeholder="Промокод" onChange={handleCode} value={code} minLength={4} maxLength={20} />
          </div>
          <div style={{ paddingLeft: 20, paddingBottom: 15, marginTop: -25 }}>
            <CustomCheckbox title="Данные введены верно" value={confirmCorrectInput} onChange={validateForm} />
            {
              error && <p style={{ color: 'red' }}>{error}</p>
            }
          </div>
          <div className="CodeActivationInput">
            <CustomButton title="Активировать" onClick={handleActivation} disabled={!confirmCorrectInput} />
          </div>
        </div>
        <div className="CodeActivationBuy">
          <img className="CodeActivationPresent" src={CodeActivationPresent} />
          <div className="CodeActivationBuyButton">
            <CustomButton title="Купить подписку в подарок" onClick={handleBuy} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CodeActivation;
