import React from 'react';
import Footer from '../../components/Footer/Footer';
import styles from "./ManagerScreen.module.css";

const prostoAppIcon = "images/ProstoappIcon.png";
const managerIcon = "images/Manager.png";

const ManagerScreen: React.FC = () => {

  return (
    <div className={styles.ManagerScreen}>
      <img className={styles.AppIcon} src={prostoAppIcon} />
      <img className={styles.ManagerIcon} src={managerIcon} />
      <p className={styles.TitleText}>Наш менеджер свяжется с вами</p>
      <Footer />
    </div>
  );
}

export default ManagerScreen;
