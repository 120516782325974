import axios from "axios";

export interface IResponse {
  httpStatus: number;
  responseStatus: number | null;
  success: boolean;
  timestamp: string | null;
  data: any | null;
  error: string | null;
  message: string | null;
}

export type TQueryData = Record<string, unknown>;

export type THeaders = Record<string, string | number>;

export interface IRequestSettings {
  headers?: THeaders;
  retryCount?: number;
  timeout?: number;
  encoding?: string;
}

class HttpService {
  get(url: string, queryData: TQueryData = {}, settings: IRequestSettings = {}): Promise<IResponse> {
    const urlWithQuery = this.addQueryParams(url, queryData);
    return request({
      method: "GET",
      url: urlWithQuery,
      ...settings,
    });
  }

  post(url: string, data: unknown = {}, settings: IRequestSettings = {}): Promise<IResponse> {
    return request({
      url,
      data,
      method: "POST",
      ...settings,
    });
  }

  delete(url: string, settings: IRequestSettings = {}): Promise<IResponse> {
    return request({
      url,
      method: "DELETE",
      ...settings,
    });
  }

  put(url: string, data: unknown = {}, settings: IRequestSettings = {}): Promise<IResponse> {
    return request({
      url,
      data,
      method: "PUT",
      ...settings,
    });
  }

  patch(url: string, data: unknown = {}, settings: IRequestSettings = {}): Promise<IResponse> {
    return request({
      url,
      data,
      method: "PATCH",
      ...settings,
    });
  }

  addQueryParams(url: string, params: TQueryData) {
    let resultUrl = url;
    let isFirst = true;
    for (const key in params) {
      const prefix = isFirst ? "?" : "&";
      resultUrl += `${prefix}${key}=${params[key]}`;
      isFirst = false;
    }
    return resultUrl;
  }
}

interface IRequestParams {
  method: "GET" | "POST" | "DELETE" | "PATCH" | "PUT";
  url: string;
  data?: unknown;
  headers?: THeaders;
  retryCount?: number;
  timeout?: number;
}

async function request({ data, method, timeout, url, headers = {} }: IRequestParams): Promise<IResponse> {
  try {
    const axiosResponse = await axios.request({
      method,
      url,
      headers,
      timeout,
      data: prepareData(data),
      transformRequest: (requestData, requestHeaders) => {
        setHeaderContentType(requestHeaders);
        return requestData;
      },
    });

    const serverData = axiosResponse.data;
    return makeResponse({
      httpStatus: axiosResponse.status,
      responseStatus: serverData.statusCode,
      success: serverData.success,
      timestamp: serverData.timestamp,
      data: serverData.data ?? null,
      message: serverData.message ?? null,
      error: serverData.error ?? null,
    });
  } catch (e) {
    const serverData = e?.response?.data ?? {};
    return makeResponse({
      httpStatus: e?.response?.status ?? 0,
      responseStatus: serverData.statusCode ?? null,
      success: false,
      data: serverData.data ?? null,
      error: serverData.error ?? null,
      message: serverData.message ?? null,
    });
  }
}

function makeResponse(params: Partial<IResponse>): IResponse {
  return {
    httpStatus: params.httpStatus ?? 0,
    responseStatus: params.responseStatus ?? null,
    success: params.success ?? false,
    data: params.data ?? null,
    timestamp: params.timestamp ?? null,
    error: params.error ?? null,
    message: params.message ?? null,
  };
}

function setHeaderContentType(headers: THeaders) {
  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json; charset=utf-8";
  }
}

function prepareData(data: unknown) {
  if (data instanceof FormData) {
    return data;
  }

  if (typeof data == "object" && data != null) {
    return JSON.stringify(data);
  }

  return data;
}

const httpService = new HttpService();
export default httpService;
