import BuyGiftSubscriptionApi from "./api";
import config from "../config/config";
import "./TinkoffFrameFix.css";

interface IPromoCode {
  id: number;
  text: string;
  price: number;
  amountTotal: number;
}

class BuySubscriptionController {
  private api = new BuyGiftSubscriptionApi();

  async buySubscription(months: number, price: number, discount: number, phone: string): Promise<IPromoCode | false> {
    const promoCode = await this.createSubscription(months, price, discount, phone);
    if (!promoCode) return false;

    // if (!await this.requestUserPayment(phone, promoCode, months)) {
    //   return false;
    // }

    return promoCode;
  }


  private async createSubscription(months: number, price: number, discount: number, phone: string): Promise<IPromoCode | null> {
    const response = await this.api.createSubscription({
      isCorporate: false,
      method: "card",
      amountTotal: 1,
      months,
      price,
      phone,
      discount
    })

    if (!response) {
      return null;
    }

    return {
      id: response.id,
      text: response.text,
      price: response.price,
      amountTotal: response.amountTotal,
    }
  }

  // private async requestUserPayment(phone: string, promoCode: IPromoCode, months: number): Promise<boolean> {
  //   const form = this.createTinkoffForm({
  //     totalPrice: promoCode.amountTotal * promoCode.price,
  //     orderId: promoCode.id,
  //     description: `Подписка на ${months} месяцев для ${phone}`,
  //     phone
  //   })
  //   window.pay(form);
  //   return true;
  // }

  // private createTinkoffForm(params: {
  //   totalPrice: number,
  //   orderId: number,
  //   description: string,
  //   phone: string,
  // }) {
  //   const receiptData = 
  //   {
  //     Phone: params.phone,
  //     EmailCompany: "help@prostoapp.com",
  //     Taxation: "usn_income",
  //     Items: [{
  //       Name: "Cертификат на подписку на приложение Prosto",
  //       Price: params.totalPrice * 100,
  //       Quantity: 1.00,
  //       Amount: params.totalPrice * 100,
  //       Tax: "none",
  //     }],
  //   }

  //   const form = document.createElement("form");

  //   const fields = document.createDocumentFragment();
  //   fields.appendChild(this.createInput("terminalkey", config.tinkoffTerminalKey));
  //   fields.appendChild(this.createInput("frame", "true"));
  //   fields.appendChild(this.createInput("language", "ru"));
  //   fields.appendChild(this.createInput("amount", params.totalPrice.toString()));
  //   fields.appendChild(this.createInput("order", params.orderId.toString()));
  //   fields.appendChild(this.createInput("description", params.description));
  //   fields.appendChild(this.createInput("phone", params.phone));
  //   fields.appendChild(this.createInput("receipt", JSON.stringify(receiptData)));


  //   form.appendChild(fields);

  //   return form;
  // }

  // private createInput(name: string, value: string ) {
  //   const input = document.createElement("input");
  //   input.type = "hidden";
  //   input.name = name;
  //   input.value = value;
  //   return input;
  // }
}

export default BuySubscriptionController
