import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import DomainRoutes from "./navigation/DomainRoutes";
import './index.css';

ReactDOM.render(
  <Router>
    <React.StrictMode>
      {/* <YMInitializer accounts={[86989752]} options={{webvisor: true, trackHash: true}} version="2" /> */}
      <DomainRoutes className="App" />
    </React.StrictMode>
  </Router>,
  document.getElementById('root')
);
