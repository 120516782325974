class DomainRouter {
  go(domain: string): void {
    window.location.host = domain;
  }

  get currentHost(): string {
    return window.location.host;
  }
}

const domainRouter = new DomainRouter();
export default domainRouter;
