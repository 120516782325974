import React, { } from 'react';
import Calculator from '../Calculator/Calculator';
import { IMainScreenState } from "../../types/IMainScreenState";
import PromoCalculator from '../PromoCalculator/PromoCalculator';
import { useHolidaysTheme } from '../../themes/useTheme';
import { useTimer } from '../../themes/useTimer';
const birthdayIcon = "images/BirthdayIcon.png";
const giftBox = "images/GiftBox.png";

interface IProps {
  promoCalculator?: boolean;
  data: IMainScreenState;
  onBuyClick: () => void;
  onDataChange: (newState: IMainScreenState) => void;
}

const UpperMainBlock: React.FC<IProps> = ({ data, onBuyClick, onDataChange, promoCalculator }) => {
  const { currentDayNumber, daysLeft, themeText, styles, isHoliday, isPink, isBirthday, isBlackFriday, nextTimeout } = useHolidaysTheme()
  useTimer(isHoliday, nextTimeout)
  // console.log('nextTimeout', nextTimeout)
  const createMarkup = (text: string, margin?: number) => {
    if (!text) return ''
    return text.split('\n').map((str, key) => <p key={key} style={{ margin: margin || 0 }}>{str}</p>)
  }

  return (
    <div className={styles.UpperMainRoot}>
      {isBirthday ?
        <img className="AppIcon" src={birthdayIcon} /> :
        <div className={styles.ProstoAppIcon}></div>
      }
      {(isHoliday && nextTimeout) &&
        <div className={styles.Timer} style={{ textAlign: 'center' }}>
          <div className={styles.TimerText}>
            До конца {isPink && daysLeft > 0 ? currentDayNumber + ' дня' : ''} акции осталось:
          </div>
          <div style={{ height: 100 }} id="timercec83824777491b4b59b1d363b66b0190"></div>
        </div>
      }
      <div className={styles.BackgroundImageContainer}>
        <div className={styles.BackgroundImage} />
      </div>
      <div className={styles.MainBlock}>
        <div className={styles.LeftTextContainer} />
        <div className={styles.MainContainer}>
          {promoCalculator
            ? <PromoCalculator data={data} onClick={onBuyClick} onDataChange={onDataChange} promoCalculator={promoCalculator} />
            : <Calculator
              title={themeText.calcTitle}
              subtitle={themeText.calcSubtitle}
              data={data}
              onClick={onBuyClick}
              onDataChange={onDataChange}
            />}
        </div>
        <a className={styles.HowItWorks} href="#advices">Как это работает?</a>
        <div className={styles.TextContainer}>
          <div className={styles.MainTitle}>{createMarkup(themeText.title, 15)}</div>
          <div className={styles.MainSubtitle || styles.AdditionalText}>{createMarkup(themeText.subtitle, 15)}</div>
          <div className={styles.AdditionalText}>{createMarkup(themeText.subtitleExtra, 15)}</div>
        </div>

        {isBlackFriday && <div className={styles.WavesBackground} />}
        {isBlackFriday &&
          <p className={styles.MobileTitle}>
            {createMarkup(themeText.calcTitle)}
            <p className="CalculatorSubitle">{themeText.calcSubtitle}</p>
            <img src={giftBox} width={'100%'} />
          </p>
        }
      </div>
    </div>
  );
}

export default UpperMainBlock;
