import React, { useCallback, useEffect, useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import CustomInput from '../CustomInput/CustomInput';
import './Calculator.css';
import { IMainScreenState } from "../../types/IMainScreenState";
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import SubscriptionSelect from '../Selector/SubscriptionSelect';
import { useFetch } from '../../core/http/fetch.hook';
import { PaymentMethodSelector } from '../PaymentMethodSelector/PaymentMethodSelector';
import config from '../../core/config/config';
import { useHolidaysTheme } from '../../themes/useTheme';
import { isValidEmail } from '../../screens/MainScreen/MainScreen';
import domainRouter from '../../navigation/domainRouter';

export interface ISubscription {
  months: number;
  price: number;
  discount1: number;
  discount2: number;
  finalPrice: number;
}

export interface ICalculatorProps {
  title?: string
  subtitle?: string
  data: IMainScreenState;
  onClick?: () => void;
  onDataChange?: (state: IMainScreenState) => void;
}

// Podarok
const Calculator: React.FC<ICalculatorProps> = ({ title = 'Оплатить сертификат', subtitle, data, onClick, onDataChange = () => { } }) => {
  const { isBlackFriday, styles } = useHolidaysTheme()

  const [availableSubscriptions, setAvailableSubscriptions] = useState<ISubscription[]>([])
  const [activeSubscription, setActiveSubscription] = useState<ISubscription>(
    {
      months: 1,
      price: 379,
      discount1: 0,
      discount2: 0,
      finalPrice: 379
    }
  );

  const { response: subscriptionPrices } = useFetch('promocodes/prices')

  const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value ?? "";
    onDataChange({
      ...data,
      email: email.toLowerCase().trim(),
    })
  }, [data, onDataChange])

  const [emailCheckboxTouched, setEmailCheckboxTouched] = useState(false)
  const handleConfirmEmailCheckbox = useCallback((value) => {
    setEmailCheckboxTouched(value)
    if (data.email === '') return
    if (!isValidEmail(data.email)) return
    onDataChange({
      ...data,
      confirmEmail: data.confirmEmail ? false : true,
    })
  }, [data, onDataChange])

  const onSelectSubscription = useCallback((subscription: ISubscription) => {
    setActiveSubscription(subscription)
    onDataChange({
      ...data,
      months: subscription.months,
      price: subscription.finalPrice,
      // discount: subscription.discount,
    });
  }, [data, onDataChange])

  useEffect(() => {
    if (isBlackFriday) {
      onSelectSubscription({
        months: 36,
        price: 4550,
        discount1: 0,
        discount2: 0,
        finalPrice: 4550,
      })
    } else {
      if (subscriptionPrices !== undefined) {
        const currentHost = domainRouter.currentHost
        console.log('currentHost', currentHost)
        console.log('subscriptionPrices', subscriptionPrices)
        const { podarok, avo } = subscriptionPrices
        if (currentHost === 'avo.prostoapp.ru' && avo?.length) {
          avo.sort((a: ISubscription, b: ISubscription) => (
            (a.months < b.months) ? 1 : -1
          ))
          setAvailableSubscriptions(avo)
          setActiveSubscription(avo[0])
          onSelectSubscription(avo[0])
        } else if (podarok?.length) {
          podarok.sort((a: ISubscription, b: ISubscription) => (
            (a.months < b.months) ? 1 : -1
          ))
          setAvailableSubscriptions(podarok)
          setActiveSubscription(podarok[0])
          onSelectSubscription(podarok[0])
        }
      }
    }

  }, [subscriptionPrices])

  const createMarkup = (text?: string) => {
    if (!text) return ''
    return text.split('\n').map((str, key) => <p key={key} style={{ margin: 0 }}>{str}</p>)
  }

  return (
    <div className="CalculatorBlock">
      <div>
        <div className="CalculatorTitle">
          {createMarkup(title)}
          <p className="CalculatorSubitle">{createMarkup(subtitle)}</p>
        </div>

        <CustomInput
          placeholder="Ваша электронная почта*"
          onChange={handleEmailChange}
        />

        <p className="CalculatorAdditionalText">
          {isBlackFriday ? 'На указанную почту будут отправлены доступы' : 'На указанную почту будет отправлен сертификат'}
        </p>
        {isBlackFriday ?
          <>
            <div className='FlexBlock'>
              <p className="CalculatorText">
                Доступ в приложение<br />
                Prosto  <a className='Muted'>6300 ₽</a>
              </p>
              <p className="CalculatorTextBordered">3 года</p>
            </div>
            <div className='FlexBlock'>
              <p className="CalculatorText">
                Практикум ментальной<br />
                устойчивости <a className='Muted'>5100 ₽</a>
              </p>
              <p className="CalculatorTextBordered">6 месяцев</p>
            </div>
            <div className='FlexBlock'>
              <p className="CalculatorText">
                Онлайн-конференция <br /><a className='Muted'>4900 ₽</a>
              </p>
              <p className="CalculatorTextBordered">навсегда</p>
            </div>
            <div className='FlexBlock'>
              <p className="CalculatorText">
                Итого к оплате
              </p>
              <p className="CalculatorText"><a className='Muted'>17 100 ₽</a></p>
              <p className="CalculatorText"><b>4 550 ₽</b></p>
            </div>
          </> :
          <div className="CalculatorBottomBlock">
            <div className="CalculatorBottomLeftBlock">
              <p className="CalculatorTextFirst">Месяцев</p>
              <p className="CalculatorText">Цена</p>
              {!!activeSubscription.discount1 && <p className="CalculatorText">Скидка</p>}
              {!!activeSubscription.discount2 && <p className="CalculatorText">Доп.скидка</p>}
              {!!activeSubscription.discount1 && <p className="CalculatorText">Итого к оплате</p>}
            </div>
            <div className="CalculatorBottomRightBlock">
              <SubscriptionSelect
                availableSubscriptions={availableSubscriptions}
                activeSubscription={activeSubscription}
                onSelect={onSelectSubscription}
              />
              <div className="CalculatorBottomRightBlockText">
                <p className="CalculatorText">
                  {activeSubscription.price} <span className="RubleIcon">₽</span>
                </p>
                {!!activeSubscription.discount1 && <p className="CalculatorText">-{activeSubscription.discount1}%</p>}
                {!!activeSubscription.discount2 && <p className="CalculatorText">-{activeSubscription.discount2}%</p>}
                {!!activeSubscription.discount1 &&
                  <p className="CalculatorText">
                    {activeSubscription.finalPrice} <span className="RubleIcon">₽</span>
                  </p>}
              </div>
            </div>
          </div>
        }

      </div>
      <div className="CalculatorFooter">
        <div style={{ paddingLeft: 70}}>
          <CustomCheckbox
            title="Подтверждаю, что Email введен верно"
            value={data.confirmEmail}
            onChange={handleConfirmEmailCheckbox}
          />
        </div>

        {emailCheckboxTouched && data.email === '' &&
          <p style={{ textAlign: 'center', color: 'red' }}>Необходимо ввести Email</p>
        }
        {emailCheckboxTouched && data.email !== '' && !isValidEmail(data.email) &&
          <p style={{ textAlign: 'center', color: 'red' }}>Неверный формат Email</p>
        }
        <PaymentMethodSelector
          value={data.paymentMethod}
          onChange={(value) => {
            onDataChange({
              ...data,
              paymentMethod: value,
            })
          }}
        />
        <CustomButton
          className={styles['CustomButton']}
          title={data.loading ? "Обработка ..." : "Оплатить"}
          onClick={onClick}
          disabled={!data.canPay}
        />
        <p className="CalculatorOffer">
          {"Оформляя покупку, вы соглашаетесь с "}
          <a className="CalculatorLink" href={config.privacyUrl} target='_blanc'>Договором оферты</a>
        </p>
      </div>
    </div >
  );
}

export default Calculator;
