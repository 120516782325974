import React, { useCallback } from "react";
import { Label, Radio } from "theme-ui";
import { TPaymentMethod } from "../../core/buyGiftSubscription/controller";

import styles from "../CorporateCalculator/CorporateRadio.module.css";


interface IPaymentMethodSelectorProps {
  value: TPaymentMethod;
  onChange: (value: TPaymentMethod) => void;
}

export const PaymentMethodSelector = ({ value, onChange }: IPaymentMethodSelectorProps) => {

  const onPaymentMethodChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;
      if (newValue !== value) {
        onChange(newValue as TPaymentMethod);
      }
    }, [onChange, value]);

  return (
    <div className={styles.Root}>
      <Label className={styles.RadioItem}>
        <Radio
          className={styles.RadioInput}
          value="tinkoff"
          checked={value === "tinkoff"}
          onChange={onPaymentMethodChange}
        />
        <span className={styles.Text}>Карта российского банка</span>
      </Label>
      <Label className={styles.RadioItem}>
        <Radio
          className={styles.RadioInput}
          value="stripe"
          checked={value === "stripe"}
          onChange={onPaymentMethodChange}
        />
        <span className={styles.Text}>Карта Visa, Mastercard (не Россия)</span>
      </Label>
    </div>
  )
}
