import { useEffect, useState } from "react";
import config from "../config/config";

export const useFetch = (url: string) => {
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState<any>()

  useEffect(() => {
    setIsLoading(true)
    fetch(`${config.apiUrl}/${url}`)
      .then(res => res.json())
      .then((response) => {
        setResponse(response.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log('fetch err', err);
        setIsLoading(false)
      })
  }, [])

  return {
    isLoading,
    response
  }
}