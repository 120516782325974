import React from 'react';
import './AdvicesBlock.css';

interface IProps {
  isBlackFriday?: boolean
  isPink?: boolean
  isCorp?: boolean
  firstImage?: string;
  firstText?: string;
  secondText?: string;
  items?: any
}

const letter = "images/Letter.png";
const certificate = "images/Certificate.png";
const certificateInLetter = "images/CertificateInLetter.png"
const blackCertificateInLetter = "images/black-friday/BlackCertificateInLetter.png"
const wallet = "images/Wallet.png";
const access = "images/black-friday/access.png"
const hours24 = "images/black-friday/24.png"
const envelope = "images/black-friday/letter.png"

const blackFridayItems = [
  {
    image: access,
    text: `Вы покупаете доступ к эксклюзивному контенту Prosto: конференции и онлайн-интенсива нет в открытом доступе`
  },
  {
    image: hours24,
    text: `Только 24 часа на покупку Prosto Box для ментального здоровья со скидкой 74%`
  },
  {
    image: envelope,
    text: `Доступы и инструкция придут вам на почту после оплаты`
  },
]

const corpItems = [
  {
    image: wallet,
    text: "Оплатите подарочный сертификат на подписку для ваших сотрудников"
  },
  {
    image: letter,
    text: "Вы получите сертификат, который можно распечатать или отправить через мессенджер"
  },
  {
    image: certificate,
    text: `Получатель сертификата сможет легко активировать промокод, инструкция есть в сертификате`
  },
]

const podarokItems = [
  {
    image: wallet,
    text: "Купи сертификат на подписку в подарок или себе"
  },
  {
    image: letter,
    text: "Вы получите сертификат, который можно распечатать или отправить через мессенджер"
  },
  {
    image: certificate,
    text: `Получатель сертификата сможет легко активировать промокод, инструкция есть в сертификате`
  },
]

const pinkItems = [
  {
    image: "images/pink/wallet.png",
    text: "Оплатите подарочный сертификат для себя или близкого"
  },
  {
    image: "images/pink/letter.png",
    text: "Вы получите сертификат, который можно распечатать или отправить через мессенджер"
  },
  {
    image: "images/pink/certificate.png",
    text: `Получатель сертификата сможет легко активировать промокод, инструкция есть в сертификате`
  },
]


const AdvicesBlock: React.FC<IProps> = ({
  isBlackFriday = false, isPink, isCorp = false
}) => {
  const items = isBlackFriday ? blackFridayItems :
    isCorp ? corpItems :
      isPink ? pinkItems : podarokItems

  return (
    <div className="AdvicesBlock">
      <div>
        {items.map((item, key) => (
          <div key={key} className="AdvicesContainer">
            <img className="AdvicesImage" src={item.image} />
            <p className="AdvicesTextFirst">{item.text}</p>
          </div>
        ))}
      </div>
      <div>
        <img className="AdvicesLetterImage" src={isBlackFriday ? blackCertificateInLetter : certificateInLetter} />
      </div>
    </div>
  );
}

export default AdvicesBlock;
