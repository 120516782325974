import moment from "moment";
import { useLayoutEffect } from "react";

export const useTimer = (show: boolean, tillDate?: number) => {
  // console.log('tillDate', tillDate)

  // from https://megatimer.ru

  useLayoutEffect(() => {
    if (!show) return
    var _id = "cec83824777491b4b59b1d363b66b0190";
    new window.MegaTimer(
      _id, {
        "view": [1, 1, 1, 1],
      "type": {
        "currentType": "1",
        "params": { "usertime": false, "tz": "3", "utc": tillDate }
      },
      "design": {
        "type": "plate", 
        "params": {
          "round": "0", 
          "background": "opacity", 
          "effect": "slide", 
          "space": "0", 
          "separator-margin": "19",
          "number-font-family": { 
            "family": "Comfortaa", "link": "<link href='//fonts.googleapis.com/css?family=Comfortaa&subset=latin,cyrillic' rel='stylesheet' type='text/css'>" 
          },
          "number-font-size": "24", 
          "number-font-color": "#919191", 
          "padding": "6", 
          "separator-on": true, 
          "separator-text": ":", 
          "text-on": true,
          "text-font-family": { 
            "family": "Arial" 
          }, 
          "text-font-size": "12", 
          "text-font-color": "#999999"
        }
      }, 
      "designId": 4, 
      "theme": "white", 
      "width": 630, 
      "height": 98
    })
  }, [show])
  
}