import React from "react";
import styles from "./UpperCorporateBlock.module.css";
import CorporateCalculator from "../CorporateCalculator/CorporateCalculator";
import { ICorporateScreenState } from "../../types/ICorporateScreenState";

interface IProps {
  data: ICorporateScreenState;
  onBuyClick: () => void;
  onDataChange: (newState: ICorporateScreenState) => void;
}


const UpperCorporateBlock: React.FC<IProps> = ({ data, onBuyClick, onDataChange }) => {
  return (
    <div className={styles.Root}>
      <div className={styles.ProstoAppIcon} />
      <div className={styles.MainBlock}>
        <div className={styles.LeftTextContainer} />
        <div className={styles.MainContainer}>
          <CorporateCalculator data={data} onDataChange={onDataChange} onClick={onBuyClick} />
        </div>
        <a className={styles.HowItWorks} href="#advices">Как это работает?</a>
        <div className={styles.TextContainer}>
          <p className={styles.MainTitle}>Корпоративный подарок</p>
          <p className={styles.AdditionalText}>
            Поделись спокойствием, хорошим сном и осознанностью.
            Подарочный сертификат открывает доступ ко всем медитациям и образовательным видео приложения Prosto
          </p>
        </div>
      </div>
      <div className={styles.BackgroundImageContainer}>
        <div className={styles.BackgroundImage} />
      </div>
    </div>
  );
}

export default UpperCorporateBlock;
