export const blackThemeText = {
  calcTitle: `Prosto Box для 
  ментального здоровья`,
  calcSubtitle: 'со скидкой 74%',
  title: 'Впервые за всю историю проекта мы устраиваем Чёрную пятницу',
  subtitle: `Только 24 ноября в продаже бокс для ментального здоровья вашей Кукушечки:`,
  subtitleExtra: `
  • Доступ Prosto на 3 года
  • Онлайн-практикум ментальной устойчивости, доступ на 6 месяцев 
  • Онлайн-конференция Prosto, доступ навсегда
  ❤️ Можно оплатить банковской картой
  ❤️ Доступны ко всем материалам придут вам на почту
  ❤️ Все покупатели набора в Черную пятницу могут выиграть призы
  `,
}