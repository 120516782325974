import React, { useCallback } from "react";
import styles from "./CorporateRadio.module.css";
import { Label, Radio } from "theme-ui";
import { TPayMethod } from "../../types/TPayMethod";

interface IProps {
  value?: TPayMethod;
  onChange?: (newValue: TPayMethod) => void;
}

const CorporateRadio: React.FC<IProps> = ({ value= "card", onChange= () => {} }) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;
      if (newValue !== value) {
        onChange(newValue as TPayMethod);
      }
    },
    [onChange, value],
  );

  return (
    <div className={styles.Root}>
      <Label className={styles.RadioItem}>
        <Radio
          className={styles.RadioInput}
          value="card"
          checked={value === "card"}
          name="CorporateRadio"
          onChange={handleChange}
        />
        <span className={styles.Text}>Оплатить онлайн</span>
      </Label>
      <Label className={styles.RadioItem}>
        <Radio
          className={styles.RadioInput}
          value="bill"
          checked={value === "bill"}
          name="CorporateRadio"
          onChange={handleChange}
        />
        <span className={styles.Text}>Выставить счет</span>
      </Label>
    </div>
  );
}

export default CorporateRadio;
