import React, { useCallback } from 'react';
import './Selector.css';
import IntegerSelector from "../IntegerSelector/IntegerSelector";
import { ISubscription } from '../Calculator/Calculator';

const selectorIcon = "images/Selector.png";

interface IDates {
  [key: string]: string;
}

const dates: IDates = {
  1: "1 месяц",
  3: "3 месяца",
  6: "6 месяцев",
  12: "12 месяцев",
};

interface IProps {
  availableSubscriptions: ISubscription[]
  activeSubscription: ISubscription
  onSelect: (subscription: ISubscription) => void
}

const SubscriptionSelect: React.FC<IProps> = ({
  availableSubscriptions, activeSubscription, onSelect,
}) => {

  // const handleCountChange = useCallback((newValue: number) => {
  //   if (newValue >= 3) {
  //     setCount(newValue);
  //   }
  // }, [])

  const onSelectChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    onSelect(availableSubscriptions.find(
      availableSubscription => availableSubscription.months === Number(value)
    ) || availableSubscriptions[0])
  }, [onSelect, availableSubscriptions])

  return (
    <div>
      {/* {showCountSelector && <IntegerSelector className="IntegerSelector" onChange={handleCountChange} />} */}
      <div className="Selector">
        <div>
          <select
            title='select'
            onChange={onSelectChange}
            className="InputDate"
            value={activeSubscription.months}
          // defaultValue={availableSubscriptions[0]?.months}
          // disabled={useAvoDiscount}
          >
            {
              availableSubscriptions.map((subscription) => (
                <option key={subscription.months} value={subscription.months}>
                  {dates[subscription.months.toString()]}
                </option>
              ))
            }
          </select>
          {dates[activeSubscription.months]}
          <img alt='select' className="SelectorImage" src={selectorIcon} />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSelect;
