import { TPayMethod } from "../../types/TPayMethod";
import BuyGiftSubscriptionApi from "../buyGiftSubscription/api";

interface IPromoCode {
  id: number;
  text: string;
  price: number;
  amountTotal: number;
  paymentLink?: string;
}

interface IBuyParams {
  phone: string;
  email: string;
  subscriptionCount: number;
  subscriptionDuration: number;
  subscriptionPrice: number;
  promoCode: string;
  payMethod: TPayMethod;
  discount: number;
  corporateDiscount?: number;
  companyName?: string,
  inn?: string,
  workPhone?: string,
  legalAddress?: string
}

class BuyCorporateSubscriptionController {
  private api = new BuyGiftSubscriptionApi();

  async buyPromoCode(params: IBuyParams): Promise<IPromoCode | false> {
    const promoCode = await this.createPromoCode(params);
    if (!promoCode) return false;
    return promoCode;
  }

  private async createPromoCode(params: IBuyParams): Promise<IPromoCode | null> {
    const response = await this.api.createPromoCode({
      isCorporate: true,
      method: params.payMethod,
      amountTotal: params.subscriptionCount,
      text: params.promoCode || undefined,
      phone: params.phone,
      email: params.email,
      months: params.subscriptionDuration,
      price: params.subscriptionPrice,
      discount: params.discount,
      discountCorporation: params.corporateDiscount,
      paymentMethod: 'tinkoff',
    });

    if (!response) {
      return null;
    }

    return {
      id: response.id,
      text: response.text,
      price: response.price,
      amountTotal: response.amountTotal,
      paymentLink: response.link,
    }
  }

  // private async requestUserPayment({ subscriptionCount, subscriptionDuration, email }: IBuyParams, promoCode: IPromoCode): Promise<boolean> {
  //   const form = this.createTinkoffForm({
  //     totalPrice: promoCode.price,
  //     orderId: promoCode.id,
  //     description: `${subscriptionCount} подписок на ${subscriptionDuration} месяцев для ${email}`,
  //     email
  //   })
  //   window.pay(form);
  //   return true;
  // }

  // private createTinkoffForm(params: {
  //   totalPrice: number,
  //   orderId: number,
  //   description: string,
  //   email: string,
  // }) {

  //   const receiptData =
  //   {
  //     Email: params.email,
  //     EmailCompany: "help@prostoapp.com",
  //     Taxation: "usn_income",
  //     Items: [{
  //       Name: "Cертификат на подписку на приложение Prosto",
  //       Price: params.totalPrice * 100,
  //       Quantity: 1.00,
  //       Amount: params.totalPrice * 100,
  //       Tax: "none",
  //     }],
  //   }

  //   const form = document.createElement("form");

  //   const fields = document.createDocumentFragment();
  //   fields.appendChild(this.createInput("terminalkey", config.tinkoffTerminalKey));
  //   fields.appendChild(this.createInput("frame", "true"));
  //   fields.appendChild(this.createInput("language", "ru"));
  //   fields.appendChild(this.createInput("amount", params.totalPrice.toString()));
  //   fields.appendChild(this.createInput("order", params.orderId.toString()));
  //   fields.appendChild(this.createInput("description", params.description));
  //   fields.appendChild(this.createInput("email", params.email));
  //   fields.appendChild(this.createInput("receipt", JSON.stringify(receiptData)));

  //   form.appendChild(fields);

  //   return form;
  // }

  // private createInput(name: string, value: string) {
  //   const input = document.createElement("input");
  //   input.type = "hidden";
  //   input.name = name;
  //   input.value = value;
  //   return input;
  // }
}

export default BuyCorporateSubscriptionController;
