import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CodeActivation from '../screens/CodeActivation/CodeActivation';
import CodeActivated from "../screens/CodeActivated/CodeActivated";

const CodeRoutes: React.FC = () => {

  return (
    <div>
      <Switch>
        <Route
          exact path='/'
          component={CodeActivation}
        />
        <Route
          path='/activated'
          component={CodeActivated}
        />
      </Switch>
    </div>
  );
}

export default CodeRoutes;
