const config = {
  apiUrl:
    window._prosto_env_.REACT_APP_API_URL ||
    process.env.REACT_APP_API_URL ||
    "",
  tinkoffTerminalKey:
    window._prosto_env_.REACT_APP_TERMINAL_KEY ||
    process.env.REACT_APP_TERMINAL_KEY ||
    "",
  giftRoute:
    window._prosto_env_.REACT_APP_GIFT_ROUTE ||
    process.env.REACT_APP_GIFT_ROUTE ||
    "",
  codeRoute:
    window._prosto_env_.REACT_APP_CODE_ROUTE ||
    process.env.REACT_APP_CODE_ROUTE ||
    "",
  corpRoute:
    window._prosto_env_.REACT_APP_CORP_ROUTE ||
    process.env.REACT_APP_CORP_ROUTE ||
    "",
  promoRoute:
  window._prosto_env_.REACT_APP_PROMO_ROUTE ||
  process.env.REACT_APP_PROMO_ROUTE ||
  "",
  privacyUrl: 'https://help.prostoapp.ru/#!/tab/437811820-1',
};

export default config;
