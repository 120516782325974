import React from 'react';
import './Footer.css';

interface IProps {
  backgroundColor?: string;
  textColor?: string;
  showSellerInfo?: boolean
}
const Footer: React.FC<IProps> = ({ backgroundColor, textColor, showSellerInfo = true }) => {
  const thisYear = new Date().getFullYear()

  return (
    <div className="FooterBlock" style={{ backgroundColor: backgroundColor || '#fff' }}>
      <p className="FooterText" style={{ color: textColor || '#000' }}>© Просто {thisYear}</p>
      <div className="FooterButtonContainer">
        <a className="FooterLink" href="https://apps.apple.com/ru/app/просто-медитация-и-сон/id1490891826?mt=8">
          Appstore
        </a>
        <a className="FooterLink" href='https://play.google.com/store/apps/details?id=com.prostoapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          GooglePlay
        </a>
      </div>
      <p className="FooterText" style={{ color: textColor || '#000' }}>Служба поддержки <a href="mailto: pr@prostoapp.com" className="FooterMail">pr@prostoapp.com</a></p>
      <br />
      {showSellerInfo &&
        <p className="FooterText" style={{ color: textColor || '#000' }}>
          ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ БАРСУКОВ АЛЕКСАНДР АЛЕКСАНДРОВИЧ,
          ИНН 366208819519, ОГРН 316366800118452,
          pr@prostoapp.com, 89608576775
        </p>
      }

    </div>
  );
}


export default Footer;
