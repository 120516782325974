import ActivatePromoCodeCodeApi from "./api";

const ACTIVATE_ERRORS: Record<number, string> = {
  1013: "Промокод уже активирован",
  1014: "Промокод не найден",
  1015: "Промокод не оплачен",
  1016: "Некорректные символы в промокоде",
  1017: "Вы уже активировали этот промокод",
};

interface IActivateResult {
  success: boolean;
  data: any;
  errorMessage?: string;
}

class ActivateCodePromocodeController {
  private api = new ActivatePromoCodeCodeApi();

  async activateCodePromocode(promocode: string): Promise<IActivateResult> {
    const response = await this.api.activate({
      promocode
    });

    return {
      success: response.success,
      data: response.data,
      errorMessage: ACTIVATE_ERRORS[response.responseStatus!] ?? undefined,
    };
  }
}

export default ActivateCodePromocodeController;
