import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CertificateScreen from '../screens/CertificateScreen/CertificateScreen';
import MainScreen from "../screens/MainScreen/MainScreen";
import HB24Screen from '../screens/HB24Screen/HB24Screen';
import ForeverScreen from '../screens/ForeverScreen/ForeverScreen';

const MainRoutes: React.FC = () => {

  return (
    <div>
      <Switch>
        <Route
          exact path='/'
          component={MainScreen}
        />
        <Route
          exact path='/certificate'
          component={CertificateScreen}
        />
        {/* <Route
          exact path='/hb24'
          component={HB24Screen}
        /> */}
        {/* <Route
          exact path='/forever'
          component={ForeverScreen}
        /> */}
      </Switch>
    </div>
  );
}

export default MainRoutes;
