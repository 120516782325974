import React, { useCallback, useState } from "react";
import styles from "./IntegerSelector.module.css";

interface IProps {
  className?: string;
  value?: number;
  onChange?: (newValue: number) => void;
}

const IntegerSelector: React.FC<IProps> = ({ className = "", onChange = () => {} }) => {
  const [value, setValue] = useState<number>(3);
  const [canIncrease, setCanIncrease] = useState(checkCanIncrease(value));
  const [canDecrease, setCanDecrease] = useState(checkCanDecrease(value));

  const updateCans = useCallback((newValue: number) => {
    setCanIncrease(checkCanIncrease(newValue));
    setCanDecrease(checkCanDecrease(newValue));
  }, []);

  const handleIncreaseClick = useCallback(() => {
    if (canIncrease) {
      const newValue = value + 1;
      updateCans(newValue);
      setValue(newValue);
      onChange(newValue);
    }
  }, [canIncrease, value, updateCans, onChange]);

  const handleDecreaseClick = useCallback(() => {
    if (canDecrease) {
      const newValue = value - 1;
      updateCans(newValue);
      setValue(newValue);
      onChange(newValue);
    }
  }, [canDecrease, value, updateCans, onChange]);

  const handleInputBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (value >= 3) {
        setValue(value);
        onChange(value);
        updateCans(value);
      } else {
        setValue(3);
        onChange(3);
        updateCans(3);
      }
    },
    [onChange, value, updateCans],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === '') {
        return setValue(0)
      }
      const newValue = Number.parseInt(event.target.value);
      if (!Number.isNaN(newValue) && newValue >= 1) {
        setValue(newValue);
      }
    },
    [],
  );

  return (
    <div className={`${styles.Root} ${className}`}>
      <div
        className={`${styles.IconContainer} ${styles.LeftIcon} ${canDecrease ? "" : styles.Disabled}`}
        onClick={handleDecreaseClick}
      >
        -
      </div>
      <div className={styles.InputContainer}>
        <input className={styles.Input} type="number" value={value || ''} onChange={handleInputChange} onBlur={handleInputBlur} />
      </div>
      <div
        className={`${styles.IconContainer} ${styles.RightIcon} ${canIncrease ? "" : styles.Disabled}`}
        onClick={handleIncreaseClick}
      >
        +
      </div>
    </div>
  )
}

function checkCanIncrease(value: number): boolean {
  return true;
}

function checkCanDecrease(value: number): boolean {
  return value > 3;
}

export default IntegerSelector;
