import { defaultThemeText } from "./default/defaultThemeText";
import { pinkThemeText } from "./pink/pinkThemeText";
import { birthdayThemeText } from "./birthday/birthdayThemeText";
import defaultStyles from './default/default.module.css';
import pinkStyles from './pink/pink.module.css';
import blackStyles from './black/black.module.css';
import moment from 'moment-timezone';
import { blackThemeText } from "./black/blackThemeText";

export const useHolidaysTheme = () => {
  let themeText, styles, isHoliday = false, nextTimeout
  const today = moment().get('date')
  let daysLeft = 0
  let currentDayNumber = 0
  const thisMonth = moment().format('MM')
  const thisYear = moment().get('year')
  const isPink = moment().isBetween(`${thisYear}-01-11 10:00`, `${thisYear}-02-13 23:59`)
  // const isBirthday = moment().isBetween(`${thisYear}-09-21 10:30`, `${thisYear}-09-24 12:01`)
  const isBirthday = false
  const isBlackFriday = moment().isBetween(`${thisYear}-11-22 00:00`, +moment.tz(`${thisYear}-11-25 13:30`, "Europe/Moscow").toDate())
  // console.log('isBlackFriday', isBlackFriday)
  // const isBlackFriday = false
  isHoliday = isBirthday || isPink || isBlackFriday
  if (isBirthday) {
    nextTimeout = +moment.tz(`${thisYear}-09-24 12:00`, "Europe/Moscow").toDate()
    themeText = birthdayThemeText
    styles = defaultStyles
  } else if (isPink) {
    nextTimeout = +moment.tz(`${thisYear}-02-14 20:00`, "Europe/Moscow").toDate()
    // daysLeft = 14 - today
    // currentDayNumber = today - 10
    themeText = pinkThemeText
    styles = pinkStyles
  } else if (isBlackFriday) {
    nextTimeout = +moment.tz(`${thisYear}-11-25 13:30`, "Europe/Moscow").toDate()
    themeText = blackThemeText
    styles = blackStyles
  } else {
    themeText = defaultThemeText
    styles = defaultStyles
  }

  return {
    today,
    thisMonth,
    currentDayNumber,
    daysLeft,
    themeText,
    styles,
    isHoliday,
    isPink,
    isBirthday,
    isBlackFriday,
    nextTimeout,
  }
}