import React from 'react';
import AdvicesBlock from '../AdvicesBlock/AdvicesBlock';
import CustomButton from '../CustomButton/CustomButton';
import { useHolidaysTheme } from '../../themes/useTheme';
import './BottomMainBlock.css';

interface IProps {
  onActivateClick: () => void;
  removeTitle?: boolean;
  buttonTitle?: string;
  downloadIcon?: boolean;
  firstImage?: string;
  firstText?: string;
  secondText?: string;
  mainScreen?: boolean;
  isCorp?: boolean
}

const BottomMainBlock: React.FC<IProps> = ({ onActivateClick, removeTitle, buttonTitle, downloadIcon, firstImage, firstText, secondText, mainScreen = true, isCorp = false }) => {
  const { isBlackFriday, isPink, styles } = useHolidaysTheme()

  return (
    <div className="MainBottomBlock" style={{
      zIndex: 1,
      backgroundColor: isBlackFriday ? '#EFECEC' : '#fff'
    }}>
      <a id="advices" className="Title">Как это работает?</a>

      <AdvicesBlock
        isCorp={isCorp}
        isBlackFriday={isBlackFriday}
        isPink={isPink}
      />

      <div className="BottomMainBlockButton">
        <CustomButton
          className={styles['CustomButton']}
          downloadIcon={downloadIcon}
          title={buttonTitle || 'Активировать промокод'}
          onClick={onActivateClick}
        />
      </div>
    </div>
  );
}

export default BottomMainBlock;
