import config from "../config/config";
import httpService, {IResponse} from "../http/HttpService";

const PROMO_CODES_URL = `${config.apiUrl}/promocodes`;

interface IActivateRequest {
  phone: string;
  promocode: string;
}

class ActivatePromoCodeApi {
  activate(data: IActivateRequest): Promise<IResponse> {
    return httpService.post(`${PROMO_CODES_URL}/activate`, data);
  }
}

export default ActivatePromoCodeApi;
