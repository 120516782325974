import React, { } from 'react';
import CertificateInstructionsBlock from '../../components/CertificateBlock/CertificateBlock';
import CustomButton from '../../components/CustomButton/CustomButton';
import Footer from '../../components/Footer/Footer';
import { useHolidaysTheme } from '../../themes/useTheme';
import './CertificateScreen.css';

const birthdayIcon = "images/BirthdayIcon.png";
const voices = "images/voices.png";
const voicesMobile = "images/voices-mobile.png";

const CertificateScreen: React.FC = () => {
  const { isHoliday, isBirthday, isBlackFriday, isPink, styles } = useHolidaysTheme()
  return (
    <div className={styles.CertificateBlock || "CertificateBlock"}>
      {isBirthday && <img className="AppIcon" src={birthdayIcon} />}
      {isPink && <img className={styles.AppIcon || "AppIcon"} src={"images/pink/pink-logo.png"} />}
      {!isHoliday && <div className={styles.ProstoAppIcon}></div>}

      {isPink && <img className={styles.CertificatesTitleImage || "AppIcon"} src={"images/pink/sent-letter.png"} />}

      <div className="hideOnMobile">
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <p className={styles.CertificateTitleText || "TitleText"}>
            Поздравляем <br />
            с покупкой сертификата
            {isPink &&
              <span style={{ color: "rgba(235, 125, 143, 1)" }}> со скидкой 72% 🎉</span>
            }!
            <br />
            Доступы на почте!
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={voices} />
            <p className="CertificateText"
              style={{ width: 260, textAlign: 'left' }}
            >
              С нами более 2 255 536 человек — присоединяйся!
              <br /><br />
              Российские звёзды медитируют сами и их голоса ведут тебя в медитациях:
              <br /><br />
              Юра Борисов<br />
              Никита Ефремов<br />
              Максим Матвеев<br />
              Равшана Куркова<br />
              Дарья Мельникова<br />
              Сергей Чонишвили<br />
              Н. Н. Дроздов<br />
            </p>
          </div>
        </div>
      </div>

      <div className="hideOnDesktop">

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <p className={styles.CertificateTitleText || "TitleText"}>
            Поздравляем <br />
            с покупкой сертификата
            {isPink &&
              <span style={{ color: "rgba(235, 125, 143, 1)" }}> со скидкой 72% 🎉</span>
            }!
            <br />
            Доступы на почте!
          </p>
          <p className="CertificateText">
            С нами более 2 255 536 человек — <br />
            присоединяйся!
            <br /><br />
            Российские звёзды медитируют сами <br />
            и их голоса ведут тебя в медитациях:
            <br />
          </p>
          <br />
          <p className="CertificateText"
            style={{ marginBottom: -200, textAlign: 'left', marginLeft: '50%' }}
          >
            Юра Борисов<br />
            Никита Ефремов<br />
            Максим Матвеев<br />
            Равшана Куркова<br />
            Дарья Мельникова<br />
            Сергей Чонишвили<br />
            Н. Н. Дроздов<br />
          </p>
          <img src={voicesMobile} width={'100%'} />
        </div>
      </div>

      <CertificateInstructionsBlock
        isBlackFriday={isBlackFriday}
        isPink={isPink}
      />

      {
        isHoliday && (
          <>
            {/* <div className='hideOnMobile'> */}
            <p className={styles.TitleTextBottom || "TitleTextBottom"}>
              Участвуй в розыгрыше подарков <br />
              общей стоимостью более 470 000₽ <br />
              в наших соцсетях! <br />
              Жми, чтобы увидеть условия 👇
            </p>
            {/* </div> */}
            {/* <div className='hideOnDesktop'>
              <p className={styles.TitleTextBottom || "TitleTextBottom"}>
                Участвуй в розыгрыше<br /> подарков общей <br />
                стоимостью более 470 000₽ <br />
                в наших соцсетях! <br />
                Жми, чтобы увидеть условия 👇
              </p>
            </div> */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
              <a className="Link" href="https://bit.ly/3Sv90xp" target='_blanc'>
                <CustomButton
                  className={styles['CustomButton']}
                  title={'Участвовать'}
                />
              </a>
            </div>
          </>
        )
      }

      <Footer
        backgroundColor={isBlackFriday ? '#262626' : '#fff'}
        textColor={isBlackFriday ? '#fff' : '#000'}
      />
    </div >
  );
}

export default CertificateScreen;
