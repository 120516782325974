export const pinkThemeText = {
  calcTitle: 'Оплатить сертификат',
  calcSubtitle: '',
  title: `Купи сертификат
   в подарок себе 
   и близкому`,
  subtitle: `Prosto празднует день Любви. Только 4 дня самые большие скидки на годовую подписку:
  11.02 - 72%
  12.02 - 72%
  13.02 - 72%
  14.02 - 72%
  `,
  subtitleExtra: `
  🤍 Сертификат открывает доступ ко всем медитациям и видео приложения Prosto.
  🤍 Можно купить любое количество сертификатов для себя или близкого человека.
  🤍 Сертификат можно активировать в течение двух лет.
  `,
}