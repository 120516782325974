import React from 'react';
import './CustomButton.css';

const downloadIconImage = "images/Download.png";

export interface ICustomButtonProps {
  title: string;
  onClick?: () => void;
  icon?: string
  disabled?: boolean;
  downloadIcon?: boolean;
  className?: string;
}

const CustomButton: React.FC<ICustomButtonProps> = ({ title, onClick, icon, disabled, downloadIcon, className = "" }) => {
  return (
    <div className={`CustomButtonBlock ${className}`}>
      <button className="CustomButton" onClick={onClick} disabled={disabled}>
        {icon && <img className="ButtonIcon" src={icon} />}
        {downloadIcon && <img className="ButtonIcon" src={downloadIconImage} />}
        <span>{title}</span>
      </button>
    </div>
  );
}

export default CustomButton;
